<template>
  <div class="container">
    <div class="row justify-content-center" style="margin-top: 45px; margin-bottom: 15%;">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">Lähetä uusi salasana</div>
          <div class="card-body">
            <div v-if="error" class="alert alert-danger">{{error}}</div>
            <form action="#" @submit.prevent="submit">
              <div class="form-group row">
                <label for="email" class="col-md-4 col-form-label text-md-right">Sähköposti</label>

                <div class="col-md-6">
                  <input
                    id="email"
                    type="email"
                    class="form-control"
                    name="email"
                    value
                    required
                    autofocus
                    v-model="form.email"
                  />
                </div>
              </div>

              <div class="form-group row mb-0">
                <div class="col-md-8 offset-md-4">
                  <button type="submit" class="btn btn-danger  btn-t btn-danger-t">Lähetä uusi salasana</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  data() {
    return {
      form: {
        email: "",
        password: ""
      },
      error: null
    };
  },
  methods: {
    submit() {
      firebase
        .auth()
        .sendPasswordResetEmail(this.form.email)
        .then(data => {
          console.log(data);
          this.$router.push('/dashboard/login');
        })
        .catch(err => {
          this.error = err.message;
        });
    }
  }
};
</script>

<style>


</style>